<template>
	<v-row class="ma-1 align-center">
		<v-col cols="12" :sm="firstcol || 5">
			<h5 v-text="title" />
		</v-col>
		<v-col cols="12" :sm="lastcol || 7">
			<slot></slot>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: { title: String, default: '', firstcol: Number, lastcol: Number },
};
</script>

<style>
</style>